import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSortBy, useTable, usePagination } from "react-table";
import Skeleton from "@mui/material/Skeleton";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";


function Table({
    columns,
    onRowClick,
    data,
    pagination,
    setPagination,
    selectedRowIndex,
    isLoadingData,
  }) {
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const { orders } = useSelector((state) => state.orders);
    // const data = useMemo(() => orders || [], [orders]);
  
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
      useSortBy,
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: 10 },
      },
      usePagination
    );
  
    // useEffect(() => {
    //   if (data.length > 0) {
    //     setIsLoading(false);
    //   }
    // }, [data]);

    useEffect(() => {
      if (data.length > 0) {
        setIsLoading(false);
      } else if (isLoadingData) {
        setIsLoading(true);
      }
    }, [data, isLoadingData]);
  
    if (isLoading) {
      return (
        <div className="">
          <Skeleton
            variant="rectangular"
            width={"100%"}
            height={"10px"}
            className="mb-3 mt-2"
          />
          <Skeleton
            variant="rectangular"
            width={"80%"}
            height={"40px"}
            className="mb-3"
          />
          <Skeleton
            variant="rectangular"
            width={"70%"}
            height={"80px"}
            className="mb-3"
          />
          <Skeleton
            variant="rectangular"
            width={"90%"}
            height={"20px"}
            className="mb-3"
          />
          <Skeleton
            variant="rectangular"
            width={"50%"}
            height={"40px"}
            className="mb-3"
          />
          <Skeleton
            variant="rectangular"
            width={"70%"}
            height={"80px"}
            className="mb-3"
          />
          <Skeleton
            variant="rectangular"
            width={"90%"}
            height={"20px"}
            className="mb-3"
          />
          <Skeleton
            variant="rectangular"
            width={"100%"}
            height={"50px"}
            className="mb-3"
          />
        </div>
      );
    }
  
    return (
      <>
        <table {...getTableProps()} className="w-full">
          <thead className="px-10 py-4 w-full font-semibold text-lg tracking-wide text-left capitalize whitespace-nowrap bg-[#C9ECF7] text-zinc-800 max-md:px-5 max-md:max-w-full">
            <tr>
              {headerGroups.map((headerGroup) =>
                headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className="px-6 py-[2px] text-center"
                  >
                    {column.render("Header")}
                  </th>
                ))
              )}
            </tr>
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={`gap-5 self-center mt-6 w-full tracking-wide capitalize max-w-[983px] text-zinc-800 max-md:flex-wrap max-md:max-w-full cursor-pointer ${
                    selectedRowIndex === i ? "bg-gray-200" : ""
                  }`}
                  style={{ borderBottom: "1px solid #ccc" }}
                  onClick={() => onRowClick(row.original)}
                >
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className="px-6 py-3 whitespace-nowrap text-center"
                    >
                      <div className="text-lg text-gray-900">
                        {cell.render("Cell")}
                      </div>
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
  
        <footer className="flex justify-center text-lg self-center px-5 mt-2  w-full">
          <div className="flex gap-5 text-center text-black font-semibold w-2/12">
            <button
              onClick={() => setPagination((prevValue) => prevValue - 10)}
              // disabled={pagination <= 0}
              disabled={pagination <= 0 || isLoadingData}
              className="p-2 rounded-full bg-gray-200"
              style={{ width: "40px", height: "40px" }}
            >
              &lt;
            </button>
            <div className="flex-auto my-auto">
              {t("Results")}{" "}
              <strong>
                {pagination + 1}-{pagination + data.length}
              </strong>
            </div>
            <button
              onClick={() => setPagination((prevValue) => prevValue + 10)}
              // disabled={data?.length < 10}
              disabled={data?.length < 10 || isLoadingData}
              className="p-2 rounded-full bg-gray-200"
              style={{ width: "40px", height: "40px" }}
            >
              &gt;
            </button>
          </div>
        </footer>
      </>
    );
  }

  export default Table;