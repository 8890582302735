import "./App.css";
import ProjectRoutes from "./Routes";
import { Bounce, ToastContainer } from "react-toastify";
import "./global.css";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";

import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";

Bugsnag.start({
  apiKey: "694c291210e1a7dc2bdef390801e629e",
  plugins: [new BugsnagPluginReact()],
});
BugsnagPerformance.start({ apiKey: "694c291210e1a7dc2bdef390801e629e" });

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const App = () => {

  // const handleClick = () => {
  //   throw new Error("Test error");
  // };

  return (
    <ErrorBoundary>
      <div className="App">
        <ProjectRoutes />
        <ToastContainer
          autoClose={2000}
          theme="colored"
          position="top-right"
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* <button onClick={handleClick}>Trigger error</button> */}
      </div>
    </ErrorBoundary>
  );
};

export default App;
