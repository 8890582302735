import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closePosSession,
  openSessionInfo,
} from "../../Redux/Action/loginAction";
import { useTranslation } from "react-i18next";
import CashIn_Modal from "./CashIn_Modal";
import { drop_arrow_black } from "../../assets";
import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";
import { setCloseSession } from "../../Redux/Action/sessionActions";

const LoadingSpinner = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-black bg-opacity-50">
      <div className="spinner">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

const CloseSessionModal = ({ closeModal, handleClose }) => {
  const { t, i18n } = useTranslation();
  const [sessionDetail, setSessionDetail] = useState();
  const [diffValue, setDiffValue] = useState();
  const [change, setChange] = useState();
  const [countedCash, setcountedCash] = useState(0);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.login?.user?.result);
  const [showCashInModal, setShowCashInModal] = useState(false); // State to control cash in modal visibility
  const [showSettleOptions, setShowSettleOptions] = useState(false);
  const [reason, setReason] = useState("");
  const [processing, setProcessing] = useState(false); // Add this state

  const [showSettle, setShowSettle] = useState(false);

  const [cashOut, setCashOut] = useState(change);
  const countedCashRef = useRef(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const response = await dispatch(
        openSessionInfo(userData?.user_id, userData?.company_id)
      );
      setSessionDetail(response);
      setChange(response?.cash_difference);
      setDiffValue(response?.cash_difference);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      dispatch(setCloseSession(true))
      // localStorage.setItem("closeSession", true);
    }
  };

  const handleCloseSession = async () => {
    setLoading(true);
    setIsButtonDisabled(true);

    try {
      if (showSettleOptions) {
        const cashOutResponse = await axiosInstance.post(URLS.CASH_IN_OUT, {
          session_id: userData?.session_id,
          type: "cash_out",
          amount: cashOut,
          reason: reason,
        });

        if (cashOutResponse?.data?.result?.status_code === 200) {
          const closeSessionResponse = await dispatch(
            closePosSession({
              session_id: userData?.session_id,
              diff_amount: 0,
              // counted_amount: countedCash,
              counted_amount:
                change === 0 ? sessionDetail?.cash_difference : countedCash,
              is_accept: checked,
            })
          );

          if (closeSessionResponse?.result?.status === 200) {
            handleClose();
          }
        } else {
          console.error("Cash out operation failed.");
        }
      } else {
        const closeSessionResponse = await dispatch(
          closePosSession({
            session_id: userData?.session_id,
            diff_amount: 0,
            counted_amount: countedCash,
            is_accept: checked,
          })
        );

        if (closeSessionResponse?.result?.status === 200) {
          handleClose();
        }
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
      setIsButtonDisabled(false);
    }
  };

  const handleDiffChange = (value) => {
    let change = parseFloat((diffValue + Number(value))?.toFixed(2));
    setcountedCash(value);
    setChange(change);
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      if (!countedCash || (change !== 0 && !showSettleOptions)) {
        e.preventDefault();
      } else if (!processing) {
        setProcessing(true);
        handleCloseSession().finally(() => setProcessing(false));
      }
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      // Normalize the key input for consistent handling
      const normalizedKey =
        e.key === "Escape"
          ? "Escape"
          : e.key === "ArrowUp"
          ? "ArrowUp"
          : e.shiftKey
          ? "Shift"
          : e.key;

      if (normalizedKey === "Escape") {
        e.preventDefault();
        closeModal();
        localStorage.setItem("eKeyPressed", false);
        // localStorage.setItem("closeSession", false)
        dispatch(setCloseSession(false))
      } else if (normalizedKey === "Shift") {
        e.preventDefault();
        setShowSettleOptions((prev) => !prev); // Toggle the state
      } else if (normalizedKey === "ArrowUp") {
        e.preventDefault();
        setShowSettleOptions((prev) => prev); // Ensure state consistency
        if (countedCashRef.current) {
          countedCashRef.current.focus();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (showSettleOptions) {
      setCashOut(change);
    }
  }, [showSettleOptions, change]);

  // console.log("SSSSSSSSSSS", sessionDetail?.cash_difference);
  

  return (
    <div className="fixed inset-0 z-20 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur">
      <div className="relative flex flex-col pb-2 bg-white max-w-[765px] rounded-[20px]  overflow-hidden">
        <div className="flex gap-5 px-12 pt-4 pb-1 w-full text-3xl font-semibold text-left bg-white text-zinc-800 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <div className="flex-auto">
            {t("Closing Summary")}
            <button
              type="button"
              onClick={closeModal}
              className="absolute top-5 right-10 text-gray-600 hover:text-gray-700"
            >
              <svg
                xmlns=""
                className="w-8 h-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="flex flex-col px-12 mt-4 w-full max-md:px-5 max-md:max-w-full">
          <img
            loading="lazy"
            src=""
            className="mt-2 w-full border border-solid border-slate-200 stroke-[1px] stroke-slate-200 max-md:max-w-full"
          />
          <div className="flex gap-5 justify-between mt-2.5 text-base capitalize max-md:flex-wrap max-md:max-w-full">
            <div className="text-zinc-800">{t("Total Sales")}</div>
            <div className="font-semibold text-black">
              EGP {sessionDetail?.total_sales}
            </div>
          </div>
          <img
            loading="lazy"
            src=""
            className="mt-2 w-full border border-solid border-slate-200 stroke-[1px] stroke-slate-200 max-md:max-w-full"
          />
          <div className="flex gap-5 justify-between mt-2.5 text-base capitalize text-zinc-800 max-md:flex-wrap max-md:max-w-full">
            <div>{t("Total Invoices")}</div>
            <div className="font-semibold">
              EGP {sessionDetail?.toal_invoices}
            </div>
          </div>
          <img
            loading="lazy"
            src=""
            className="mt-2 w-full border border-solid border-slate-200 stroke-[1px] stroke-slate-200 max-md:max-w-full"
          />
          <div className="flex gap-5 justify-between mt-2.5 text-base capitalize max-md:flex-wrap max-md:max-w-full">
            <div className="text-zinc-800">{t("Opening Cash")}</div>
            <div className="font-semibold text-black">
              EGP {sessionDetail?.opening_cash}
            </div>
          </div>
          <img
            loading="lazy"
            src=""
            className="mt-2 w-full border border-solid border-slate-200 stroke-[1px] stroke-slate-200 max-md:max-w-full"
          />
          <div className="flex gap-5 justify-between mt-2.5 text-base capitalize max-md:flex-wrap max-md:max-w-full">
            <div className="text-zinc-800">{t("Cash In")}</div>
            <div className="font-semibold text-black">
              EGP {sessionDetail?.cash_in_amt}
            </div>
          </div>
          <img
            loading="lazy"
            src=""
            className="mt-2 w-full border border-solid border-slate-200 stroke-[1px] stroke-slate-200 max-md:max-w-full"
          />
          <div className="flex gap-5 justify-between mt-2.5 text-base capitalize max-md:flex-wrap max-md:max-w-full">
            <div className="text-zinc-800">{t("Cash Out")}</div>
            <div className="font-semibold text-black">
              EGP{" "}
              {i18n.language === "ar" && sessionDetail?.cash_out_amt < 0
                ? `${-sessionDetail?.cash_out_amt}-`
                : sessionDetail?.cash_out_amt}
            </div>
          </div>
          <img
            loading="lazy"
            src=""
            className="mt-2 w-full border border-solid border-slate-200 stroke-[1px] stroke-slate-200 max-md:max-w-full"
          />
          <div className="flex gap-5 justify-between mt-2.5 text-base capitalize text-zinc-800 max-md:flex-wrap max-md:max-w-full">
            <div>{t("Total No Of Orders")}</div>
            <div className="font-semibold">{sessionDetail?.total_orders}</div>
          </div>
          <img
            loading="lazy"
            src=""
            className="mt-2 w-full border border-solid border-slate-200 stroke-[1px] stroke-slate-200 max-md:max-w-full"
          />
          <div className="flex gap-5 justify-between mt-2.5 text-base capitalize text-zinc-800 max-md:flex-wrap max-md:max-w-full">
            <div>{t("Collected Cash")}</div>
            <div className="font-semibold">
              EGP {sessionDetail?.collected_cash}
            </div>
          </div>
          <img
            loading="lazy"
            src=""
            className="mt-2 w-full border border-solid border-slate-200 stroke-[1px] stroke-slate-200 max-md:max-w-full"
          />
          <div className="flex gap-5 justify-between mt-2.5 text-base capitalize text-zinc-800 max-md:flex-wrap max-md:max-w-full">
            <div>{t("Bank Collection")}</div>
            <div className="font-semibold">
              EGP {sessionDetail?.bank_collection}
            </div>
          </div>
          <img
            loading="lazy"
            src=""
            className="mt-2 w-full border border-solid border-slate-200 stroke-[1px] stroke-slate-200 max-md:max-w-full"
          />
          <div className="flex gap-5 justify-between mt-2.5 text-base capitalize text-zinc-800 max-md:flex-wrap max-md:max-w-full">
            <div>{t("Total Cash Available")}</div>
            <div className="font-semibold">
              EGP {-sessionDetail?.cash_difference?.toFixed(2)}
            </div>
          </div>
          <img
            loading="lazy"
            src=""
            className="mt-2 w-full border border-solid border-slate-200 stroke-[1px] stroke-slate-200 max-md:max-w-full"
          />
          <div className="flex gap-5 mt-5 max-md:flex-wrap max-md:max-w-full">
            <div className="flex flex-col flex-1 grow shrink-0 items-start py-2 pr-20 pl-6 bg-white rounded-2xl border border-solid basis-0 border-neutral-400 border-opacity-50 text-zinc-800 w-5/12 max-md:px-5">
              <div className="text-base">{t("Difference")}</div>
              <div
                className="text-3xl mt-5 font-semibold"
                style={{ color: change === 0 ? "green" : "red" }}
              >
                {countedCash === "-"
                  ? -sessionDetail?.cash_difference
                  : isNaN(change)
                  ? 0 // <--- Add this check
                  : change === 0
                  ? 0
                  : change < 0
                  ? -change?.toFixed(2)
                  : typeof change === "number" && change % 1 !== 0
                  ? i18n.language === "ar"
                    ? `${Math.abs(change)?.toFixed(2)}-`
                    : -change?.toFixed(2)
                  : i18n.language === "ar"
                  ? `${Math.abs(change)?.toFixed(2)}-`
                  : -change}
              </div>
              {/* <div
                className="text-3xl mt-5 font-semibold"
                style={{ color: change === 0 ? "green" : "red" }}
              >
                {countedCash === "-"
                  ? -sessionDetail?.cash_difference
                  : change === 0
                  ? 0
                  : change < 0
                  ? -change.toFixed(2)
                  : typeof change === "number" && change % 1 !== 0
                  ? i18n.language === "ar"
                    ? `${Math.abs(change).toFixed(2)}-`
                    : -change.toFixed(2)
                  : i18n.language === "ar"
                  ? `${Math.abs(change).toFixed(2)}-`
                  : -change}
              </div> */}
            </div>
            <div className="flex flex-col flex-1 grow shrink-0 items-start py-2 pr-20 pl-6 text-lg bg-white rounded-2xl border border-solid basis-0 border-neutral-400 border-opacity-50 w-5/12 max-md:px-5">
              <div className="text-red-600">
                <span className="text-[#7EC242]">{t("Counted Cash")}</span>{" "}
              </div>
              <input
                type="text"
                ref={countedCashRef}
                autoFocus={true}
                className="bg-white rounded-lg border-stone-300 h-[60px] focus:outline-none px-2 font-bold text-zinc-800 w-11/12 text-3xl"
                value={countedCash}
                style={{ direction: "ltr" }}
                onChange={(e) => {
                  let newValue = e.target.value;

                  newValue = newValue.replace(/[،ز]/g, ".");
                  if (newValue.match(/^-?\d*\.?\d{0,2}$/)) {
                    handleDiffChange(newValue);
                  }
                }}
                onKeyPress={handleEnterKeyPress}
                maxLength={10}
              />
            </div>
          </div>

          {change < 0 && (
            <>
              <div
                className="flex flex-row text-lg font-semibold  mt-5 cursor-pointer"
                onClick={() => setShowSettleOptions(!showSettleOptions)}
              >
                {t("Settle amount by cash out")}
                <span className="cursor-pointer">
                  <img
                    className="mt-1 ms-2 w-5 h-5"
                    src={drop_arrow_black}
                    alt="arrow"
                  />
                </span>
              </div>

              {showSettleOptions && (
                <div className="flex gap-5 mt-5 max-md:flex-wrap max-md:max-w-full">
                  <div className="flex flex-col flex-1 grow shrink-0 items-start py-2 pr-20 pl-6 bg-white rounded-2xl border border-solid basis-0 border-neutral-400 border-opacity-50 w-5/12 max-md:px-5">
                    <div className="text-base">{t("Cash Out")}</div>
                    <input
                      type="text"
                      value={(-cashOut)?.toFixed(2)}
                      readOnly
                      className="bg-white rounded-lg border-stone-300 h-[25px] focus:outline-none px-2 font-bold text-zinc-800 w-11/12 text-xl"
                    />
                  </div>
                  <div className="flex flex-col flex-1 grow shrink-0 items-start py-2 pr-20 pl-6 bg-white rounded-2xl border border-solid basis-0 border-neutral-400 border-opacity-50 w-5/12 max-md:px-5">
                    <div className="text-base">{t("Reason")}</div>
                    <input
                      type="text"
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                      autoFocus
                      className="bg-white rounded-lg border-stone-300 h-[25px] focus:outline-none px-2 font-bold text-zinc-800 w-[105%] text-xl"
                      onKeyPress={handleEnterKeyPress}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="flex gap-5 self-center mb-2 mt-6 w-full text-xl font-semibold text-center capitalize max-w-[665px] max-md:flex-wrap max-md:max-w-full">
          <button
            className="grow justify-center items-center  w-3/6 py-3.5 text-[#7EC242] bg-white rounded-lg border-2 border-[#7EC242] border-solid max-md:px-5 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-opacity-50"
            onClick={closeModal}
          >
            {t("Continue Selling")}
          </button>
          <button
            disabled={isButtonDisabled || (change !== 0 && !showSettleOptions)}
            style={{
              opacity: change === 0 || showSettleOptions ? 1 : 0.5, 
            }}
            onClick={handleCloseSession}
            className="grow justify-center items-center  w-3/6  py-3.5 text-white bg-[#7EC242] rounded-lg max-md:px-5 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-opacity-50"
          >
            {loading && <LoadingSpinner />}
            {t("Close Session")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CloseSessionModal;
